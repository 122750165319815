import Service from '@/service';

/**
 * @name app
 * @description Exposes menu store module
 */
export default {
	/**
	 * @method @static @get @name namespaced
	 * @description Is this module namespaced?
	 */
	namespaced: true,

	/**
	 * @method @static @get @name state
	 * @description Expose state as an object, compatible with vue stores
	 */
	state: {
		site: null,
		logs: [
			{
				id: 4,
				posted: '2024/12/03',
				title: 'Eligibility and Priorities',
				content: '<p class="text-body-1 mb-3"> Lifetime Mortgage (LTM) priority column output amended to accommodate launch of ERC free plans.</p>',
				version: '1.2.4'
			},
			{
				id: 3,
				posted: '2024/07/08',
				title: 'Eligibility and Priorities',
				content: '<p class="text-body-1 mb-3"> Payment Term Lifetime Mortgages (PTLM) have been added under the Lifetime Mortgage (LTM) umbrella.</p>',
				version: '1.2.3'
			},
			{
				title: 'Additional editable rows in Product Comparison Tool',
				content: `<ul>
							<li>
								<p class="text-body-1 mb-2">Users now have the ability to add fully customised rows to input additional products.</p>
							</li>
							<li>
								<p class="text-body-1 mb-2">This feature increases flexibility by allowing users to enter another product’s information</p>
							</li>
							<li>
								<p class="text-body-1 mb-3">The additional customised rows will be fully documented on the PDF output.</p>
							</li>
						</ul>`,
				posted: '2024/05/01',
				id: 2,
				version: '1.2.0'
			},
			{
				title: 'Enhancements to Lifetime Optional Payments features',
				content:
					"<p class=\"text-body-1 mb-3\"> The Lifetime Optional Payment column of the Product Comparison step in Tool 2 has been enhancement to show a default display of 100% of monthly interest payments with users able to edit the 'monthly repayment' amount and see a re-calculated 'Total cost of borrowing'.</p>",
				posted: '2023/12/21',
				id: 1,
				version: '1.1.0'
			}
		]
	},

	/**
	 * @method @static @get @name getters
	 * @description Expose getters as an object, compatible with vue stores
	 */
	getters: {},

	/**
	 * @method @static @get @name mutations
	 * @description Expose mutations as an object, compatible with vue stores
	 */
	mutations: {
		/**
		 * @method @static @get @name setSiteConfig
		 * @description Set site config
		 * @param {Object} state The store state
		 * @param {Object} payload The payload to set
		 */
		setSiteConfig(state, payload) {
			state.site = payload;
		}
	},

	/**
	 * @method @static @get @name actions
	 * @description Expose action as an object, compatible with vue stores
	 */
	actions: {
		/**
		 * @method @name getSiteConfig
		 * @description Get site config
		 * @returns {Object} Promise a http response
		 * @param {Object} context The store context
		 */
		getSiteConfig({ commit }) {
			Service.getSiteConfig().then(({ data }) => {
				commit('setSiteConfig', data);
			});
		},

		/**
		 * @method @name updateJson
		 * @description Update tool form data
		 * @returns {Object} Promise a http response
		 * @param {Object} context The store context
		 */
		updateJson(context, { s3Key, payload }) {
			return Service.updateJson({ s3Key, payload });
		},

		/**
		 * @method @name getJson
		 * @description Get site config
		 * @returns {Object} Promise a http response
		 * @param {Object} context The store context
		 */
		getJson(context, payload) {
			return Service.getJson(payload);
		},

		/**
		 * @method @name eligibilityAndPrioritiesPdf
		 * @description Print eligibility & priorities tool pdf
		 * @returns {Object} Promise a http response
		 * @param {Object} context The store context
		 */
		eligibilityAndPrioritiesPdf(context, payload) {
			return Service.eligibilityAndPrioritiesPdf(payload);
		},

		/**
		 * @method @name productComparisonPdf
		 * @description Print product comparison tool pdf
		 * @returns {Object} Promise a http response
		 * @param {Object} context The store context
		 */
		productComparisonPdf(context, payload) {
			return Service.productComparisonPdf(payload);
		}
	}
};
