<template>
	<v-sheet elevation="3" rounded class="pa-8 mt-2">
		<div>
			<p class="text-h6 primary--text mb-1">
				Result Summary
			</p>
			<p class="mb-6">
				You've successfully completed all the necessary steps, and you're now able to access the results for eligible products. Hover over the "more info" to get a detailed breakdown of why a customer might be considered ineligible or not meet certain criteria. Remember
				to save a PDF for your records as this session will not be saved.
			</p>
		</div>

		<v-sheet rounded class="pa-6 grey lighten-5" style="background: linear-gradient(135deg, rgb(245, 245, 245) 35%, rgba(25, 118, 210, 0.15) 100%)">
			<v-row no-gutters>
				<v-col>
					<p class="text-body-1 font-weight-medium text-uppercase primary--text mb-1">
						Customer 1
					</p>
					<dynamic-natural-language-form ref="personalForm" :form-schema="personalFormSchema" :form-data="personalFormData" :submit-button="false" readonly />
				</v-col>
			</v-row>

			<v-row v-if="numberOfClients == 2" no-gutters class="mt-6">
				<v-col class="grow">
					<p class="text-body-1 font-weight-medium text-uppercase primary--text mb-1">
						Customer 2
					</p>
					<dynamic-natural-language-form ref="personalForm2" :form-schema="personalFormSchema" :form-data="personalFormData2" :submit-button="false" readonly />
				</v-col>
			</v-row>

			<v-row no-gutters class="mt-6">
				<v-col class="grow">
					<p class="text-body-1 font-weight-medium text-uppercase primary--text mb-1">
						Mortgage Details
					</p>
					<dynamic-natural-language-form ref="mortgageForm" :form-schema="mortgageFormSchema" :form-data="mortgageFormData" :submit-button="false" readonly />
				</v-col>
			</v-row>

			<v-row no-gutters class="mt-6">
				<v-col>
					<p class="text-body-1 font-weight-medium text-uppercase primary--text mb-1">
						Expenditure Details
					</p>
					<dynamic-natural-language-form ref="expenditureForm" :form-schema="expenditureFormSchema" :form-data="expenditureFormData" :submit-button="false" readonly />
				</v-col>
			</v-row>
			<v-row no-gutters class="mt-6">
				<v-col>
					<p class="text-body-1 font-weight-medium text-uppercase primary--text mb-2">
						Affordability
					</p>

					<p v-if="!isCustomerRetired" class="text-body-1 mb-1">
						<strong>Pre-Retirement</strong> - Customer 1's calculated <strong>monthly</strong> disposable income is <strong>£{{ estimatedDisposableIncome1 | numFormat('0,00.00') }}</strong>, during which time, they're comfortable committing to <strong>monthly</strong> repayments of
						<strong>£{{ affordabilityConfig.customDisposableIncome | numFormat('0,00.00') }}</strong>
					</p>
					<p class="text-body-1 mb-0">
						<strong>Post-Retirement</strong> - Customer 1's calculated <strong>monthly</strong> disposable income is <strong>£{{ estimatedDisposableIncomeRetirement1 | numFormat('0,00.00') }}</strong>, during which time, they're comfortable committing to <strong>monthly</strong> repayments of
						<strong>£{{ affordabilityConfig.customDisposableIncomeRetirement | numFormat('0,00.00') }}</strong>
					</p>

					<div v-if="numberOfClients > 1" class="mt-2">
						<p v-if="!isCustomer2Retired" class="text-body-1 mb-1">
							<strong>Pre-Retirement</strong> - Customer 2's calculated <strong>monthly</strong> disposable income is <strong>£{{ estimatedDisposableIncome2 | numFormat('0,00.00') }}</strong>, during which time, they're comfortable committing to <strong>monthly</strong> repayments of
							<strong>£{{ affordabilityConfig.customDisposableIncome2 | numFormat('0,00.00') }}</strong>
						</p>
						<p class="text-body-1 mb-0">
							<strong>Post-Retirement</strong> - Customer 2's calculated <strong>monthly</strong> disposable income is <strong>£{{ estimatedDisposableIncomeRetirement2 | numFormat('0,00.00') }}</strong>, during which time, they're comfortable committing to <strong>monthly</strong> repayments of
							<strong>£{{ affordabilityConfig.customDisposableIncomeRetirement2 | numFormat('0,00.00') }}</strong>
						</p>
					</div>
				</v-col>
			</v-row>
			<v-row no-gutters class="mt-6">
				<v-col>
					<p class="text-body-1 font-weight-medium text-uppercase primary--text mb-2">
						Notes
					</p>

					<p class="text-body-1 mb-0" style="word-wrap: break-word">
						{{ affordabilityConfig.customerIncomeNotes || '---' }}
					</p>
				</v-col>
			</v-row>
		</v-sheet>

		<v-row no-gutters>
			<v-col>
				<p class="text-body-1 font-weight-medium text-uppercase primary--text mt-8">
					Customer Priorities
				</p>

				<div class="ma-n1">
					<v-tooltip bottom max-width="300">
						<template #activator="{ on }">
							<v-chip label outlined class="ma-1" v-on="on">
								<v-icon left color="success">
									mdi-check-circle
								</v-icon>Likely to be suitable
							</v-chip>
						</template>
						<span>This product would likely be suitable with your customer(s) preference</span>
					</v-tooltip>
					<v-tooltip bottom max-width="300">
						<template #activator="{ on }">
							<v-chip label outlined class="ma-1" v-on="on">
								<v-icon left color="amber">
									mdi-help-circle
								</v-icon>Could be suitable
							</v-chip>
						</template>
						<span>This product could be suitable with your customer(s) preference</span>
					</v-tooltip>
					<v-tooltip bottom max-width="300">
						<template #activator="{ on }">
							<v-chip label outlined class="ma-1" v-on="on">
								<v-icon left color="error">
									mdi-close-circle
								</v-icon>Unlikely to be suitable
							</v-chip>
						</template>
						<span>This product is unlikely to be suitable with your customer(s) preference</span>
					</v-tooltip>
				</div>
				<v-simple-table class="elevation-2 productTable mt-4">
					<thead style="background-color: #1976d2">
						<tr>
							<th v-for="(column, index) in columns" :key="index" class="font-weight-bold white--text py-4" :class="{ 'text-center': index }">
								<div>{{ column.title }}</div>
								<div class="text-caption">
									{{ column.subtitle }}
								</div>
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="row in priorityRows" :key="row.value">
							<td class="font-weight-bold py-2">
								{{ row.text }}
							</td>
							<td v-for="(column, index) in columns.length - 1" :key="column" class="text-center" style="width: 170px">
								<v-icon v-if="getPriorityValue(row, index) == null" color="grey" size="28">
									mdi-help-circle
								</v-icon>
								<v-icon v-else-if="getPriorityValue(row, index) === 'green'" color="success" size="28">
									mdi-check-circle
								</v-icon>
								<v-icon v-else-if="getPriorityValue(row, index) === 'amber'" color="amber" size="28">
									mdi-help-circle
								</v-icon>
								<v-icon v-else color="error" size="28">
									mdi-close-circle
								</v-icon>
							</td>
						</tr>
					</tbody>
				</v-simple-table>
			</v-col>
		</v-row>

		<v-row no-gutters>
			<v-col>
				<p class="text-body-1 font-weight-medium text-uppercase primary--text mt-8">
					Product Eligibility
				</p>
				<v-simple-table class="elevation-2 productTable mt-4">
					<thead style="background-color: #1976d2">
						<tr>
							<th v-for="(column, index) in columns" :key="index" class="font-weight-bold white--text py-4" :class="{ 'text-center': index }">
								<div>{{ column.title }}</div>
								<div class="text-caption">
									{{ column.subtitle }}
								</div>
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(row, rowIndex) in rowsExtended" v-show="row.name !== 'age2' || numberOfClients == 2" :key="row.value">
							<td class="font-weight-bold">
								{{ row.text }}
							</td>
							<td v-for="(column, index) in columns.length - 1" :key="column" class="text-center py-2" style="width: 170px">
								<div v-if="index === 2 && (row.name === 'affordability' || row.name === 'customerAffordability')" class="text-caption">
									N/A
								</div>
								<v-icon v-else-if="getValue(row, index) == null" color="grey" size="28">
									mdi-help-circle
								</v-icon>
								<v-icon v-else-if="getValue(row, index)" color="success" size="28">
									mdi-check-circle
								</v-icon>
								<div v-else class="d-flex flex-column align-center justify-center mt-1">
									<v-icon v-if="rowIndex === 4 || (rowIndex === 5 && eligibilityAmber[index])" color="amber" size="28">
										mdi-help-circle
									</v-icon>
									<v-icon v-else color="error" size="28">
										mdi-close-circle
									</v-icon>
									<v-tooltip bottom max-width="400">
										<template #activator="{ on }">
											<a class="text-caption" v-on="on"> More info </a>
										</template>
										<span v-html="getTooltipContent(row, column)" />
									</v-tooltip>
								</div>
							</td>
						</tr>
					</tbody>
				</v-simple-table>
			</v-col>
		</v-row>

		<p class="text-body-1 font-weight-medium text-uppercase primary--text mt-10">
			Additional information
		</p>
		<validation-observer ref="additionalInformationObserver">
			<validation-provider v-slot="{ errors }" name="Additional information" :rules="'max:1000'">
				<v-textarea v-model="additionalInformation.text" label="Additional information" outlined :error-messages="errors" hint="Section for the Adviser to document any additional information" persistent-hint rows="3" />
			</validation-provider>
		</validation-observer>

		<div class="d-flex mt-10">
			<v-btn color="primary" large outlined @click="$emit('go-back')">
				Back
			</v-btn>
			<v-spacer />
			<v-btn color="primary" large :loading="loading" @click="generatePdf">
				Save as PDF
			</v-btn>
		</div>
	</v-sheet>
</template>

<script>
	import { $localStorage } from '@/utils/storage';
	import { ValidationObserver, ValidationProvider } from 'vee-validate';
	import DynamicNaturalLanguageForm from '@/components/common/dynamic-natural-language-form';
	import { mapActions } from 'vuex';

	export default {
		components: {
			DynamicNaturalLanguageForm,
			ValidationObserver,
			ValidationProvider
		},

		props: {
			id: { type: Number, required: true },
			customerReference: { type: String, required: true },
			fcaNumber: { type: String, required: true },
			personalFormData: { type: Object, required: true },
			personalFormData2: { type: Object, required: true },
			mortgageFormData: { type: Object, required: true },
			expenditureFormData: { type: Object, required: true },
			expenses: { type: Object, required: true },
			estimatedDisposableIncome1: { type: Number, required: true },
			estimatedDisposableIncome2: { type: Number, required: true },
			estimatedDisposableIncomeRetirement1: { type: Number, required: true },
			estimatedDisposableIncomeRetirement2: { type: Number, required: true },
			affordabilityConfig: { type: Object, required: true },
			numberOfClients: { type: Number, required: true },
			isCustomerRetired: { type: Boolean, required: true },
			isCustomer2Retired: { type: Boolean, required: true },
			priorityRows: { type: Array, required: true },
			priorityColumns: { type: Array, required: true },
			columns: { type: Array, required: true },
			outsideCriteria: { type: Array, required: true },
			priorities: { type: Array, required: true },
			eligibilityAmber: { type: Array, required: true },
			rowsExtended: { type: Array, required: true },
			customer1Age: { type: Array, required: true },
			customer2Age: { type: Array, required: true },
			ltv: { type: Array, required: true },
			eligibility: { type: Array, required: true },
			affordability: { type: Array, required: true },
			customerAffordability: { type: Array, required: true },
			customBudget: { type: Number, required: true },
			budget: { type: Number, required: true },
			lowestPostRetirementBudget: { type: Number, required: true },
			lowestCustomBudget: { type: Number, required: true },
			monthlyRepayment: { type: Object, required: true },
			backingData: { type: Object, required: true },
			additionalInformation: { type: Object, required: true }
		},

		data() {
			return {
				loading: false,
				hideHelpDialog: false,
				personalFormSchema: {
					text: 'Customer is {customerAge} years of age and {isRetired} {plannedRetirementAge}. They have a monthly net income of {income}{incomeRetirementText}{incomeRetirement}.',
					fields: [
						{ type: 'number', name: 'customerAge', display: 'Age', rules: 'required|numeric|min_value:18|max_value:150' },
						{
							type: 'select',
							name: 'isRetired',
							display: 'Is Retired',
							rules: 'required',
							options: [
								{ value: true, text: 'is already retired' },
								{ value: false, text: 'with a planned retirement age of' }
							]
						},
						{ type: 'number', name: 'plannedRetirementAge', display: 'Planned Retirement Age', rules: 'required|numeric|min_value:18|max_value:150', conditions: { show: [{ name: 'isRetired', type: 'equals', value: false }] } },
						{ type: 'plain-text', name: 'incomeRetirementText', display: '. Estimated post retirement net monthly income is ', conditions: { show: [{ name: 'isRetired', type: 'equals', value: false }] } },
						{ type: 'number', name: 'income', prefix: '£', display: 'Income', rules: 'required|numeric', currency: true, conditions: { show: [{ name: 'isRetired', type: 'equals', value: false }] } },
						{ type: 'number', name: 'incomeRetirement', prefix: '£', display: 'Post Retirement Income', rules: 'required|numeric', currency: true }
					]
				},
				mortgageFormSchema: {
					text: "The customer's property is valued at {propertyValue} and the requested borrowing amount is {requestedBorrowingAmount}.",
					fields: [
						{ type: 'number', name: 'propertyValue', prefix: '£', display: 'Property Value', rules: 'required|numeric', currency: true },
						{ type: 'number', name: 'requestedBorrowingAmount', prefix: '£', display: 'Requested Borrowing Amount', rules: 'required|numeric', currency: true }
					]
				},
				expenditureFormSchema: {
					text: 'This Customer has monthly outgoings of {expenditure}.',
					fields: [{ type: 'number', name: 'expenditure', prefix: '£', display: 'Expenditure', rules: 'required|numeric', currency: true }]
				}
			};
		},
		computed: {
			/**
			 * @name currentDateTime
			 * @description Returns the current date and time
			 * @returns {String}
			 */
			currentDateTime() {
				return this.$moment().format('DD/MM/YYYY HH:mm');
			}
		},

		mounted() {
			this.$vuetify.goTo(0, { duration: 0 });
			if (!$localStorage.get('hideStep3HelpDialog')) this.$refs.helpDialog.open();
		},

		methods: {
			...mapActions('App', ['eligibilityAndPrioritiesPdf']),

			/**
			 * @name helpDialogChanged
			 * @description Saves the state of the help dialog to local storage
			 */
			helpDialogChanged() {
				$localStorage.set('hideStep3HelpDialog', this.hideHelpDialog);
			},

			/**
			 * @name getValue
			 * @description Returns the eligibility status of a criteria
			 * @param {Object} row
			 * @param {Number} index
			 */
			getValue(row, index) {
				switch (row.name) {
					case 'age':
						return this.numberOfClients > 1 ? this.customer1Age[index] && this.customer2Age[index] : this.customer1Age[index];
					case 'age1':
						return this.customer1Age[index];
					case 'age2':
						return this.customer2Age[index];
					case 'ltv':
						return this.ltv[index];
					case 'affordability':
						return this.affordability[index];
					case 'customerAffordability':
						return this.customerAffordability[index];
					case 'eligibility':
						return this.eligibility[index];
					default:
						break;
				}
			},

			/**
			 * @name getPriorityValue
			 * @description Returns the priority status of a criteria
			 * @param {Object} row
			 * @param {Number} index
			 */
			getPriorityValue(row, index) {
				switch (row.name) {
					case 'priority1': {
						if (this.priorities[0] == null) return null;
						return [this.priorities[0] === 1 ? 'red' : this.priorities[0] === 2 ? 'amber' : 'green', this.priorities[0] === 1 ? 'red' : this.priorities[0] === 2 ? 'amber' : 'green', this.priorities[0] === 5 ? 'green' : 'amber'][index];
					}
					case 'priority2': {
						if (this.priorities[1] == null) return null;
						return [this.priorities[1] > 3 ? 'green' : this.priorities[1] < 3 ? 'red' : 'amber', this.priorities[1] > 3 ? 'green' : this.priorities[1] < 3 ? 'red' : 'amber', this.priorities[1] < 3 ? 'green' : 'amber'][index];
					}
					case 'priority3': {
						if (this.priorities[2] == null) return null;
						return [this.priorities[2] === 1 ? 'green' : this.priorities[2] === 5 ? 'red' : 'amber', this.priorities[2] === 1 ? 'green' : this.priorities[2] === 5 ? 'red' : 'amber', this.priorities[2] === 1 ? 'red' : this.priorities[2] > 3 ? 'green' : 'amber'][index];
					}
					case 'priority4': {
						if (this.priorities[3] == null) return null;
						return [this.priorities[3] === 4 ? 'amber' : this.priorities[3] === 5 ? 'red' : 'green', this.priorities[3] > 3 ? 'amber' : 'green', this.priorities[3] === 1 ? 'red' : this.priorities[3] === 2 ? 'amber' : 'green'][index];
					}
					case 'priority5': {
						if (this.priorities[4] == null) return null;
						return [this.priorities[4] < 3 ? 'amber' : 'green', this.priorities[4] < 3 ? 'amber' : 'green', this.priorities[4] === 5 ? 'amber' : this.priorities[4] < 3 ? 'green' : 'amber'][index];
					}
					case 'priority6': {
						if (this.priorities[5] == null) return null;
						return [this.priorities[5] === 4 ? 'amber' : this.priorities[5] === 5 ? 'red' : 'green', this.priorities[5] > 3 ? 'amber' : 'green', this.priorities[5] === 1 ? 'red' : this.priorities[5] === 2 ? 'amber' : 'green'][index];
					}
					case 'priority7': {
						if (this.priorities[6] == null) return null;
						return [this.priorities[6] < 3 ? 'green' : 'amber', this.priorities[6] === 1 ? 'amber' : 'green', this.priorities[6] === 3 ? 'amber' : this.priorities[6] > 3 ? 'green' : 'red'][index];
					}
					default:
						break;
				}
			},

			/**
			 * @name getTooltipContent
			 * @description Returns the tooltip content for a criteria
			 * @param {Object} row
			 * @param {Number} columnIndex
			 * @returns {String}
			 */
			getTooltipContent(row, columnIndex) {
				let content = null;

				if (row.name === 'eligibility') {
					if (this.customer1Age[columnIndex - 1] && !(this.numberOfClients > 1 && !this.customer2Age[columnIndex - 1]) && this.ltv[columnIndex - 1] && !(!this.customerAffordability[columnIndex - 1] && columnIndex !== 3))
						return 'This product was calculated to be ineligible based on the Calculated Affordability not being met. Customer Preferred payment amount shows this product could be available.';

					if (this.customer1Age[columnIndex - 1] && !(this.numberOfClients > 1 && !this.customer2Age[columnIndex - 1]) && this.ltv[columnIndex - 1] && !(!this.affordability[columnIndex - 1] && columnIndex !== 3))
						return 'This product could be available based on the Customer(s) Preferred Affordability.';

					content = 'This product has been calculated to be ineligible after the following criteria was not met</br><ul class="criteria">';

					if (!this.customer1Age[columnIndex - 1]) {
						content += `<li>Customer 1 Age</li>`;
					}
					if (this.numberOfClients > 1 && !this.customer2Age[columnIndex - 1]) {
						content += `<li>Customer 2 Age</li>`;
					}
					if (!this.ltv[columnIndex - 1]) {
						content += `<li>LTV</li>`;
					}
					if (!this.affordability[columnIndex - 1] && columnIndex !== 3) {
						content += `<li>Calculated Affordability</li>`;
					}
					// if (!this.customerAffordability[columnIndex - 1] && columnIndex !== 3) {
					// 	content += `<li>Customer Preferred Affordability</li>`;
					// }

					content += '</ul>';

					return content;
				}

				let criteria = this.outsideCriteria.find((c) => row.name === c.name || row.name.includes(c.name));
				let product = this.columns[columnIndex].value;

				if (criteria) {
					if (criteria.name === 'affordability') {
						let budget = product === 'rio' ? this.lowestPostRetirementBudget : this.budget;
						content = (content || criteria.text).replace('{monthlyRepayment}', this.monthlyRepayment[product].toFixed(2));
						content = (content || criteria.text).replace('{budget}', budget.toFixed(2));
					} else if (criteria.name === 'customerAffordability') {
						let budget = product === 'rio' ? this.lowestCustomBudget : this.customBudget;
						content = (content || criteria.text).replace('{monthlyRepayment}', this.monthlyRepayment[product].toFixed(2));
						content = (content || criteria.text).replace('{customBudget}', budget.toFixed(2));
					} else {
						let matches = criteria.text.match(/{(.*?)}/g);
						if (matches) {
							matches.forEach((match) => {
								let key = match.replace('{', '').replace('}', '');
								content = (content || criteria.text).replace(match, this.backingData[product][key]);
							});
						}
					}
				}

				return content;
			},

			/**
			 * @name generatePdf
			 * @description Generates the PDF
			 */
			async generatePdf() {
				this.loading = true;
				setTimeout(() => (this.loading = false), 1000);

				const valid = await this.$refs.additionalInformationObserver.validate();
				if (!valid) return;

				this.$emit('update');

				const payload = {
					id: this.id,
					customerReference: this.customerReference,
					fcaNumber: this.fcaNumber,
					currentDateTime: this.currentDateTime,
					numberOfClients: this.numberOfClients,
					customer1Age: this.customer1Age,
					customer2Age: this.customer2Age,
					personalFormData: this.personalFormData,
					personalFormData2: this.personalFormData2,
					mortgageFormData: this.mortgageFormData,
					expenditureFormData: this.expenditureFormData,
					expenses: this.expenses,
					isCustomerRetired: this.isCustomerRetired,
					estimatedDisposableIncome1: this.estimatedDisposableIncome1,
					estimatedDisposableIncomeRetirement1: this.estimatedDisposableIncomeRetirement1,
					isCustomer2Retired: this.isCustomer2Retired,
					estimatedDisposableIncome2: this.estimatedDisposableIncome2,
					estimatedDisposableIncomeRetirement2: this.estimatedDisposableIncomeRetirement2,
					affordabilityConfig: this.affordabilityConfig,
					priorityValues: this.priorityRows.map((row) => ({
						name: row.name,
						text: row.text,
						value: [this.getPriorityValue(row, 0), this.getPriorityValue(row, 1), this.getPriorityValue(row, 2)]
					})),
					ltv: this.ltv,
					affordability: this.affordability,
					customerAffordability: this.customerAffordability,
					eligibilityAmber: this.eligibilityAmber,
					eligibility: this.eligibility,
					backingData: this.backingData,
					monthlyRepayment: this.monthlyRepayment,
					budget: this.budget,
					lowestPostRetirementBudget: this.lowestPostRetirementBudget,
					customBudget: this.customBudget,
					lowestCustomBudget: this.lowestCustomBudget,
					additionalInformation: this.additionalInformation
				};

				try {
					const { filename, base64 } = await this.eligibilityAndPrioritiesPdf(payload).then((data) => data.data);
					const downloadLink = document.createElement('a');
					downloadLink.href = `data:application/pdf;base64,${base64}`;
					downloadLink.download = filename;
					downloadLink.click();
					downloadLink.remove();

					if (window.opener) {
						window.opener.postMessage({ [this.id]: base64 }, '*');
						setTimeout(window.close, 100);
					}
				} catch (error) {
					alert(error.message); // TODO: Use snackbar?
				}
			}
		}
	};
</script>
<style scoped lang="scss">
	.inline-field {
		display: inline-block;
		font-weight: 600;
		border-bottom: 2px dotted #979797;
		line-height: 1.3;
		cursor: pointer;
	}
</style>
